import React from "react";
import { CopyBlock, atomOneLight } from "react-code-blocks";
import styled from "styled-components";

import CommonLinkContainer from "./commonlinkcontainer";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { saTrack } from "../../utils/segmentAnalytics";
import {
  StyledTitle4,
  StyledDescCode14,
  StyledDesc1,
  StyledDesc2,
} from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { textUppercase } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import benefit1 from "../imagescommon/authz.svg";
import benefit2 from "../imagescommon/powering-mission.svg";
import checkbox from "./images/checkbox.svg";
import checkboxEmpty from "./images/checkbox-empty.svg";

const languageDemo = `{
  “_exists”: {
    “_table”:
      { “schema”: “public”, “name”: “product” },
        “_where”: {
        “_and”: [
          { “id”: { “_ceq”: [“product_id”] } },
          { “orders”:
          { “order”: { “user_id”:
          { “_eq”: “X-Hasura-User-Id” } } } }
      ]
    }
  }
}`;

const StyledPowerfulAuthWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 490px;
  grid-gap: 120px;
  .descList {
    display: flex;
    align-items: flex-start;
    padding-bottom: 50px;
    img {
      margin-top: 3px;
      margin-right: 20px;
      width: 48px;
    }
  }
  .codeBlockWrapper {
    background: ${COLORS.white};
    box-shadow: ${ELEVATION.l_1};
    border-radius: 8px;
    display: grid;
    .codeBlockThead,
    .codeBlockTBody {
      .codeBlockDarkBlueBtn,
      .codeBlockLightBlueBtn {
        border-radius: 4px;
        color: ${COLORS.white};
        text-shadow: ${ELEVATION.l_0};
        padding: 2px 12px;
      }
      .codeBlockDarkBlueBtn {
        background-color: ${COLORS.grey_100};
      }
      .codeBlockLightBlueBtn {
        background-color: ${COLORS.grey_70};
      }
    }
    .codeBlockTopBorder {
      border-radius: 0 0 8px 8px;
    }
    .codeBlockThead {
      background-color: ${COLORS.grey_10};
      border-radius: 8px 8px 0px 0px;
      padding: 15px 24px;
      color: ${COLORS.grey_90};
    }
    .codeBlockTBody {
      padding: 15px 24px;
      background-color: ${COLORS.grey_0};
      .codeBlockFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;
        img {
          margin-right: 8px;
        }
      }
    }
    .userReviewAccess {
      background-color: ${COLORS.grey_10};
      padding: 24px;
    }
    .codeBlock {
      overflow: auto;
      .sc-gsDJrp,
      .sc-hKwCoD {
        background: transparent;
        span {
          background: transparent !important;
        }
        .sc-bdvvaa,
        .sc-dkPtyc {
          display: none;
        }
      }
      code {
        background-color: transparent;
        color: inherit;
        padding-left: 16px !important;
        .react-syntax-highlighter-line-number {
          color: ${COLORS.grey_30} !important;
          text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
        }
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    grid-gap: 70px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    .descList {
      img {
        width: 32px;
      }
    }
    .hideMobileView {
      display: none !important;
    }
  }
`;
const PowerfulAuth = () => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledPowerfulAuthWrapper>
          <div>
            <StyledDesc2 variant="grey_90" paddingBottom="pb8" css={textUppercase}>
              ENTERPRISE GRADE SECURITY
            </StyledDesc2>
            <StyledTitle4 paddingBottom="pb64">
              Built-in powerful authz: ready for external or internal API consumers
            </StyledTitle4>
            <StyledDesc1 className="descList" variant="grey_90">
              <img src={benefit1} alt="Icon" />
              Hasura’s built-in RLS style authorization engine allows you to conveniently specify
              authorization rules at a model level, and safely expose the GraphQL API to developers
              inside or outside your organization.
            </StyledDesc1>
            <StyledDesc1 className="descList" variant="grey_90">
              <img src={benefit2} alt="Icon" />
              Hasura’s authz engine is enabling agile teams in fast growing startups as well as
              powering mission-critical data access in highly regulated environments such as Fortune
              500 healthcare, financial services and US federal agencies.
            </StyledDesc1>
            <CommonLinkContainer
              decs=" Watch our Webinar on GraphQL Authorization"
              linkUrl="/events/webinar/model-level-authorization/"
              internalLink={true}
              videoIcon={true}
              onClick={() => {
                saTrack("click", {
                  placement: "ENTERPRISE GRADE SECURITY",
                  cta: "Watch our Webinar on GraphQL Authorization",
                  page: "homepage",
                  label: "Clicked Watch our Webinar on GraphQL Authorization",
                  action: "click",
                });
              }}
            />
          </div>
          <div className="codeBlockWrapper hideMobileView">
            <StyledDescCode14 className="codeBlockTable">
              <div className="codeBlockThead">
                <div>
                  Model name: <span className="codeBlockDarkBlueBtn">Reviews</span>
                </div>
              </div>
              <div className="codeBlockTBody">
                <div>
                  Role name: <span className="codeBlockLightBlueBtn">User</span>
                </div>
              </div>
              <div className="userReviewAccess">Allow reading an entity if:</div>
            </StyledDescCode14>
            <StyledDescCode14 className="codeBlock">
              <CopyBlock
                language="graphql"
                text={languageDemo}
                showLineNumbers={true}
                theme={atomOneLight}
                wrapLines={true}
                codeBlock
              />
            </StyledDescCode14>
            <StyledDescCode14 className="codeBlockTBody codeBlockTopBorder">
              <div>Allow [user] to access [columns]:</div>
              <div className="codeBlockFooter">
                <div>
                  <img src={checkbox} alt="checkbox" />
                  Categories
                </div>
                <div className="hideMobileView">
                  <img src={checkbox} alt="checkbox" />
                  SeriesData
                </div>
                <div>
                  <img src={checkboxEmpty} alt="checkbox" />
                  tenant_id
                </div>
              </div>
            </StyledDescCode14>
          </div>
        </StyledPowerfulAuthWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default PowerfulAuth;
