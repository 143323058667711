import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { CopyBlock, dracula } from "react-code-blocks";
import { isSafari } from "react-device-detect";
import styled from "styled-components";

import CommonLinkContainer from "./commonlinkcontainer";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledTabWrapper from "../shared/StyledTabWrapper";
import StyledButton from "../shared/StyledButton";
import StyledDBIconWrapper from "../shared/StyledDBIconWrapper";
import SupportedDatabases from "./SupportedDatabases";
import { Flex } from "../../globals/UIKit";
import { scrollToTop } from "../../utils/helpers";
import { saTrack } from "../../utils/segmentAnalytics";
import {
  StyledTitle4,
  StyledDescCode14,
  StyledDesc1,
  StyledDesc2,
} from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import {
  textCenter,
  removePaddTop,
  removePaddBottom,
  showTab,
  hideTab,
  textUppercase,
} from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import benefit1 from "../imagescommon/run-locally.svg";
import benefit2 from "../imagescommon/architects.svg";

import showImg from "./images/show-image.svg";
import showCode from "./images/show-code.svg";
import querySchema from "./images/schema-query.svg";
import subscribeSchema from "./images/schema-subscribe.svg";
import notufySchema from "./images/schema-notify.svg";
import mutateSchema from "./images/schema-mutate.svg";
// import oData from "./images/oData.png";
import graphql from "./images/graphql.png";
import restApi from "./images/rest-api.png";
import notifyWebM from "./images/notify.webm";
import infoBlue from "./images/info-blue.svg";
import video from "./images/video.svg";
import questionIcon from "../imagescommon/question.svg";

// import arrowBlue from "../imagescommon/arrow-blue.svg";
import { Image } from "../../globals/UIKit";

const StyledSectionWrapperGreyBg = styled(StyledSectionWrapper)`
  background-color: #f3f4f6;
`;

const StyledUnifiedGraphQLWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 490px;
  grid-gap: 120px;
  padding-top: 100px;
  border-top: 1px solid ${COLORS.grey_30};
  .descList {
    display: flex;
    align-items: flex-start;
    padding-bottom: 50px;
    img {
      margin-top: 3px;
      margin-right: 20px;
      width: 48px;
    }
  }
  .buttonWrapper {
    .pt-20 {
      padding-top: 20px;
    }
  }
  
  .databaseUnifiedQueryWrapper {
    background-color: ${COLORS.grey_100};
    border-radius: 8px;
    display: grid;
    box-shadow: ${ELEVATION.l_2};
    align-self: flex-start;
    .databaseUnifiedQueryTabWrapper {
      background-color: ${COLORS.grey_96};
      border-radius: 8px 8px 0px 0px;
      padding: 15px;
      justify-content: space-between;
    }
    .codeSnippetWrapper {
      position: relative;
      height: 567px;
      .showImg {
        position: absolute;
        right: 8px;
        bottom: 8px;
        cursor: pointer;
        z-index: 10;
        box-shadow: ${ELEVATION.l_1};
      }
      .mobileViewShow {
        display: none;
      }
      .codeSnippetBlock {
        padding-top: 24px;
        height: 435px;
      }
      .codeImg {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .codeBlockVideo {
          width: 75%;
          margin: 0 auto;;
        }
      }
      .codeImgMaxHei {
        img {
          max-height: 337px;
        }
      }
      .codeBlockContainer {
        div {
          background: transparent;
          span {
            background: transparent !important;
          }
          button {
            display: none;
          }
        }
        code {
          background-color: transparent;
          color: inherit;
          white-space: pre-wrap;
          padding-left: 16px !important;
          .react-syntax-highlighter-line-number {
            color: ${COLORS.grey_26} !important;
            text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
          }
        }
        }
      }
      .sc-gsDJrp, .sc-hKwCoD {
        background: transparent;
        span {
          background: transparent !important;
        }
        .sc-bdvvaa, .sc-dkPtyc {
          display: none;
        }
      }
      code {
        background-color: transparent;
        color: inherit;
        white-space: pre-wrap;
        padding-left: 16px !important;
        .react-syntax-highlighter-line-number {
          color: ${COLORS.grey_26} !important;
          text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
        }
      }
    }
    .databaseUnifiedQueryFooter {
      padding-bottom: 24px;
      display: flex;
      align-items: flex-start;
      padding-left: 18px;
      .info {
        margin-top: 6px;
      }
      .p24 {
        padding: 0 24px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    padding-top: 70px;
    grid-gap: 70px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    padding-top: 50px;
    grid-gap: 50px;
    .descList {
      img {
        width: 32px;
      }
    }
    .databaseUnifiedQueryWrapper {
      .codeSnippetWrapper {
        height: 550px;
        .showImg {
          z-index: 1;
        }
        .codeImg {
          text-align: center;
          img {
            width: auto;
            max-width: 80%;
          }
        }
        .mobileViewShow {
          display: block;
        }
        .mobileViewHide {
          display: none;
        }
      }
    }
  }
`;

const StyledDataBaseWrapper = styled.div`
  padding-bottom: 56px;
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    border-bottom: 1px solid ${COLORS.grey_30};
    margin-bottom: 80px;
    button {
      margin: 0 12px;
    }
  }
  ${mq.below.md} {
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      padding: 50px 0;
      margin-bottom: 50px;
      button {
        margin: 12px 0;
      }
    }
  }
`;

const StyledAPIIconWrapper = styled(StyledDBIconWrapper)`
  .dbIconWrapper {
    width: 40%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    padding-bottom: 0px;
    .dbBrandList {
      box-shadow: none;
      border: 1px solid ${COLORS.grey_10};
      &:hover {
        box-shadow: none;
      }
    }
  }

  ${mq.between("md", "lg")} {
    .dbIconWrapper {
      width: 60%;
    }
  }

  ${mq.below.md} {
    overflow: auto;
    .dbIconWrapper {
      width: 100%;
      grid-gap: 24px;
    }
  }
`;

const StyledSupportedLinkWrapper = styled.div`
  text-align: center;
  padding-bottom: 100px;
  .pt-24 {
    padding-top: 24px;
    a {
      .arrow {
        margin-left: 6px;
        display: inline-block;
      }
    }
  }

  ${mq.between("md", "lg")} {
    padding-bottom: 70px;
  }
  ${mq.below.md} {
    padding-bottom: 50px;
    text-align: left;
  }
`;

const queryDemo = `query {
  albums(limit:20) { # Data in Postgres
    title
    tracks (limit:2) { # Joined with data in SQL Server
      name
      MediaType { # Joined with data in Postgres
        name
      }
    }
  }
}`;

const mutateDemo = `mutation {
  insert_products(objects:[{
    name: "Product 1",
    image: "product1.jpg"
  }]) {
    id
  }
}`;

const subscribeDemo = `subscription fetchOrder {
  order ( where: {id: {_eq: “XX-56”}})
  {
    id
    payment
    dispatched
  }
}`;

const notifyDemo = `subscription notifyOrderPlaced {
  orders(
    where: {
      id: {
      },
      placed: {
      }
    }
  )
}`;

const supportedDatabasesState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/postgressql_lhqrrp.png",
    altText: "PostgresSQL",
    linkText: "Learn more",
    linkUrl: "/graphql/database/postgresql/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "141px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520680/main-web/db%20logos/sqlserver_hw0rlv.png",
    altText: "SQLServer",
    linkText: "Learn more",
    linkUrl: "/graphql/database/sql-server/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "143px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/googlebigquery_uuyn1u.png",
    altText: "Google BigQuery",
    linkText: "Learn more",
    linkUrl: "https://hasura.io/docs/latest/databases/bigquery/getting-started/index/",
    externalLink: true,
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "139px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/azure-postgres_stpvqs.png",
    altText: "Azure Postgres",
    linkText: "Learn more",
    linkUrl: "https://hasura.io/docs/latest/databases/postgres/azure-cosmos/",
    externalLink: true,
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "178px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/amazonaurora_wblqul.png",
    altText: "Amazon Aurora",
    linkText: "Learn more",
    linkUrl:
      "https://hasura.io/docs/latest/databases/connect-db/cloud-databases/aws-aurora/#create-pg-aws-rds-aurora",
    externalLink: true,
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "105px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681983601/main-web/db%20logos/yugabyte_th5c8j.png",
    altText: "Yugabyte",
    linkText: "Learn more",
    linkUrl: "https://hasura.io/docs/latest/databases/connect-db/cloud-databases/yugabyte/",
    externalLink: true,
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "185px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/cockroach-ec9b7fe417ed76db94a1de9c8fab291c_f5qxdv.png",
    altText: "CockroachDB",
    linkText: "Learn more",
    linkUrl: "/graphql/database/cockroachdb/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "184px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/athena_ecpuxc.png",
    altText: "Athena",
    linkText: "Learn more",
    linkUrl: "/graphql/database/amazon-athena/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "148px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/snowflake_po5gg8.png",
    altText: "Snowflake",
    linkText: "Learn more",
    linkUrl: "/graphql/database/snowflake/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "160px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/mysql_uxl3ao.png",
    altText: "MySQL",
    linkText: "Learn more",
    linkUrl: "/graphql/database/mysql/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "160px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/oracle_tcy5ja.png",
    altText: "Oracle",
    linkText: "Learn more",
    linkUrl: "/graphql/database/oracle/",
    status: "Available",
    toolTip: "Available for general use. Go try it now on Hasura Cloud.",
    width: "127px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668520679/main-web/db%20logos/mongodb_xpivye.png",
    altText: "MongoDB",
    linkText: "Express interest",
    linkUrl: "/graphql/database/mongodb/",
    status: "Planned",
    toolTip:
      "On our roadmap, but engineering work has not begun yet. Add your interest / inputs to help us prioritize.",
    width: "133px",
  },
];

export const DatabaseQueryCode = () => {
  const [currentActive, setCurrentActive] = useState("query");
  const [isShowImage, setIsShowImage] = useState(true);

  return (
    <div className="databaseUnifiedQueryWrapper">
      <StyledTabWrapper className="databaseUnifiedQueryTabWrapper">
        <button
          className={
            "databaseUnifiedQueryTab hasura-common-tab" +
            (currentActive === "query" ? " activeTab" : "")
          }
          onClick={() => setCurrentActive("query")}
        >
          Query
        </button>
        <button
          className={
            "databaseUnifiedQueryTab hasura-common-tab" +
            (currentActive === "mutate" ? " activeTab" : "")
          }
          onClick={() => setCurrentActive("mutate")}
        >
          Mutate
        </button>
        <button
          className={
            "databaseUnifiedQueryTab hasura-common-tab" +
            (currentActive === "subscribe" ? " activeTab" : "")
          }
          onClick={() => setCurrentActive("subscribe")}
        >
          Subscribe
        </button>
        <button
          className={
            "databaseUnifiedQueryTab hasura-common-tab" +
            (currentActive === "notify" ? " activeTab" : "")
          }
          onClick={() => {
            setCurrentActive("notify");
            setIsShowImage(true);
          }}
        >
          Notify
        </button>
      </StyledTabWrapper>
      <div className="codeSnippetWrapper" css={currentActive === "query" ? showTab : hideTab}>
        {isShowImage && currentActive === "query" ? (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showImg}
              alt="Show code"
              onClick={() => setIsShowImage(false)}
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showCode}
              alt="Show image"
              onClick={() => setIsShowImage(true)}
            />
          </Fragment>
        )}
        {isShowImage && currentActive === "query" ? (
          <div className="codeImg">
            <Image src={querySchema} alt="Query" width="354" height="458" />
          </div>
        ) : (
          <StyledDescCode14 className="codeSnippetBlock">
            {!isShowImage ? (
              <div className="databaseUnifiedQueryFooter">
                <Image className="info" src={infoBlue} alt="Info" />
                <StyledDescCode14 className="p24" variant="sky_20">
                  Query across tables & views and across databases with powerful filtering,
                  pagination and pattern search.
                </StyledDescCode14>
              </div>
            ) : null}
            <div className="codeBlockContainer">
              <CopyBlock
                language="graphql"
                text={queryDemo}
                showLineNumbers={true}
                theme={dracula}
                wrapLines={true}
                codeBlock
              />
            </div>
          </StyledDescCode14>
        )}
      </div>
      <div className="codeSnippetWrapper" css={currentActive === "mutate" ? showTab : hideTab}>
        {isShowImage && currentActive === "mutate" ? (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showImg}
              alt="Show code"
              onClick={() => setIsShowImage(false)}
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showCode}
              alt="Show image"
              onClick={() => setIsShowImage(true)}
            />
          </Fragment>
        )}
        {isShowImage && currentActive === "mutate" ? (
          <div className="codeImg">
            <Image src={mutateSchema} alt="Query" />
          </div>
        ) : (
          <StyledDescCode14 className="codeSnippetBlock">
            {!isShowImage ? (
              <div className="databaseUnifiedQueryFooter">
                <Image className="info" src={infoBlue} alt="Info" />
                <StyledDescCode14 className="p24" variant="sky_20">
                  Insert single or multiple objects. Make upsert operations on conflict to simplify
                  app logic.
                </StyledDescCode14>
              </div>
            ) : null}
            <div className="codeBlockContainer">
              <CopyBlock
                language="graphql"
                text={mutateDemo}
                showLineNumbers={true}
                theme={dracula}
                wrapLines={true}
                codeBlock
              />
            </div>
          </StyledDescCode14>
        )}
      </div>
      <div className="codeSnippetWrapper" css={currentActive === "subscribe" ? showTab : hideTab}>
        {isShowImage && currentActive === "subscribe" ? (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showImg}
              alt="Show code"
              onClick={() => setIsShowImage(false)}
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* eslint-disable-next-line */}
            <Image
              className="showImg"
              src={showCode}
              alt="Show image"
              onClick={() => setIsShowImage(true)}
            />
          </Fragment>
        )}
        {isShowImage && currentActive === "subscribe" ? (
          <Fragment>
            <div className="mobileViewShow">
              <div className="codeImg">
                <Image src={notufySchema} alt="Fallback" />
              </div>
            </div>
            <div className="mobileViewHide">
              <div className="codeImg">
                {isSafari ? (
                  <Image src={notufySchema} alt="Fallback" />
                ) : (
                  <video id="vid" controls={false} autoPlay muted loop className="codeBlockVideo">
                    <source src={notifyWebM} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <StyledDescCode14 className="codeSnippetBlock">
            {!isShowImage ? (
              <div className="databaseUnifiedQueryFooter">
                <Image className="info" src={infoBlue} alt="Info" />
                <StyledDescCode14 className="p24" variant="sky_20">
                  Convert any GraphQL query to a live query by using subscriptions.
                </StyledDescCode14>
              </div>
            ) : null}
            <div className="codeBlockContainer">
              <CopyBlock
                language="graphql"
                text={subscribeDemo}
                showLineNumbers={true}
                theme={dracula}
                wrapLines={true}
                codeBlock
              />
            </div>
          </StyledDescCode14>
        )}
      </div>
      <div className="codeSnippetWrapper" css={currentActive === "notify" ? showTab : hideTab}>
        {isShowImage && currentActive === "notify" ? (
          <div className="codeSnippetBlock">
            <div className="databaseUnifiedQueryFooter">
              <Image className="info" src={infoBlue} alt="Info" />
              <StyledDescCode14 className="p24" variant="sky_20">
                Create an event trigger to invoke synchronous or asynchronous business logic. Event
                triggers reliably capture events on specified tables and invoke webhooks to carry
                out any custom logic.
              </StyledDescCode14>
            </div>
            <div className="codeImg removePaddTop">
              <Image src={subscribeSchema} alt="Subscribe" />
            </div>
          </div>
        ) : (
          <StyledDescCode14 className="codeSnippetBlock">
            <div className="codeBlockContainer">
              <CopyBlock
                language="graphql"
                text={notifyDemo}
                showLineNumbers={true}
                theme={dracula}
                wrapLines={true}
                codeBlock
              />
            </div>
            {!isShowImage ? (
              <div className="databaseUnifiedQueryFooter">
                <Image className="info" src={infoBlue} alt="Info" />
                <StyledDescCode14 className="p24" variant="sky_20">
                  Create an event trigger to invoke synchronous or asynchronous business logic.
                  Event triggers reliably capture events on specified tables and invoke webhooks to
                  carry out any custom logic.
                </StyledDescCode14>
              </div>
            ) : null}
          </StyledDescCode14>
        )}
      </div>
    </div>
  );
};

const DatabaseUnified = () => {
  return (
    <>
      <StyledSectionWrapper css={removePaddTop}>
        <StyledContainerWrapper>
          <StyledUnifiedGraphQLWrapper>
            <div>
              <StyledDesc2 variant="grey_90" paddingBottom="pb8" css={textUppercase}>
                Productize your data
              </StyledDesc2>
              <StyledTitle4 paddingBottom="pb64">
                From your databases to a unified GraphQL API in just one minute
              </StyledTitle4>
              <StyledDesc1 className="descList" variant="grey_90">
                <Image src={benefit1} alt="Icon" />
                Run Hasura, locally or in the cloud, and connect it to your new or existing
                databases to instantly get a production grade GraphQL API.
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_90">
                <Image src={benefit2} alt="Icon" />
                Developers and architects love Hasura because it takes no time to get started,
                doesn’t need them to be a GraphQL expert upfront, and saves their teams months of
                recurring effort in building, shipping and maintaining their APIs.
              </StyledDesc1>
              <div className="buttonWrapper">
                <a
                  href="https://cloud.hasura.io/signup?pg=home&plcmt=body&cta=try-graphql-with-hasura&tech=default"
                  onClick={() => {
                    saTrack("click", {
                      placement: "PRODUCTIZE YOUR DATA",
                      cta: "Try GraphQL with Hasura",
                      page: "homepage",
                      label: "Clicked Try GraphQL with Hasura",
                      action: "click",
                    });
                  }}
                >
                  <StyledButton variant="blue" size="md">
                    Try GraphQL with Hasura
                  </StyledButton>
                </a>
                <StyledDesc2 className="pt-20">
                  <a
                    href="/why-hasura/#where-do-i-put-business-logic"
                    onClick={() => {
                      saTrack("click", {
                        placement: "PRODUCTIZE YOUR DATA",
                        cta: "How to handle business logic",
                        page: "homepage",
                        label: "Clicked How to handle business logic",
                        action: "click",
                      });
                    }}
                  >
                    <Image className="leftIcon md" src={questionIcon} alt="Icon" />
                    How to handle business logic?
                  </a>
                </StyledDesc2>
              </div>
            </div>
            <DatabaseQueryCode />
          </StyledUnifiedGraphQLWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
      <StyledSectionWrapperGreyBg css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledDataBaseWrapper>
            <StyledTitle4 paddingBottom="pb40" css={textCenter}>
              Supported Databases
            </StyledTitle4>
            {/*
            <StyledDBIconWrapper>
              <div className="dbIconWrapper">{userBrand}</div>
            </StyledDBIconWrapper>
            */}
            <SupportedDatabases supportedDatabasesState={supportedDatabasesState} />
            <div className="buttonWrapper">
              <Link to="/graphql/database/" onClick={scrollToTop}>
                <StyledButton variant="neutral_dark">View all Databases</StyledButton>
              </Link>
              <Link to="/graphql/database/request/" onClick={scrollToTop}>
                <StyledButton variant="transparent_white">Request a Database</StyledButton>
              </Link>
            </div>
            <StyledDesc2 paddingBottom="pb40" fontWeight="font_bold" css={textCenter}>
              Supported APIs
            </StyledDesc2>
            <StyledAPIIconWrapper>
              <Flex className="dbIconWrapper" justifyContent="center">
                <div className="dbBrandList">
                  <div className="dbBrandImg">
                    <Image src={restApi} alt="REST API" width="78" height="64" />
                  </div>
                </div>
                <div className="dbBrandList">
                  <div className="dbBrandImg">
                    <Image src={graphql} alt="GraphQL" width="91" height="64" />
                  </div>
                </div>
              </Flex>
            </StyledAPIIconWrapper>
          </StyledDataBaseWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapperGreyBg>
      <StyledSectionWrapperGreyBg css={removePaddBottom + " " + removePaddTop}>
        <StyledContainerWrapper>
          <StyledSupportedLinkWrapper>
            <CommonLinkContainer
              decs=" Can I use REST instead of GraphQL APIs and more FAQs"
              linkUrl="/why-hasura/#can-i-use-rest-instead-of-graphql-api"
              onClick={() => {
                saTrack("click", {
                  placement: "PRODUCTIZE YOUR DATA",
                  cta: "Can I use REST instead of GraphQL APIs and more FAQs",
                  page: "homepage",
                  label: "Clicked Can I use REST instead of GraphQL APIs and more FAQs",
                  action: "click",
                });
              }}
            />
            <StyledDesc2 className="pt-24">
              <a
                href="/events/webinar/get-started-with-hasura/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  saTrack("click", {
                    placement: "PRODUCTIZE YOUR DATA",
                    cta: "Watch our getting started webinar",
                    page: "homepage",
                    label: "Clicked Watch our getting started webinar",
                    action: "click",
                  });
                }}
              >
                <Image className="leftIcon md" src={video} alt="Icon" />
                Watch our getting started webinar<div className="arrow">{">"}</div>
              </a>
            </StyledDesc2>
          </StyledSupportedLinkWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapperGreyBg>
    </>
  );
};

export default DatabaseUnified;
