import React from "react";
// import { Link } from "gatsby";
import styled, { css } from "styled-components";

import { Image, Text, Box, Flex } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";
import { StyledTitle2B, StyledDesc1 } from "../shared/StyledTypography";
import { saTrack } from "../../utils/segmentAnalytics";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
import tickBlue from "../imagescommon/tick-blue.svg";
// import video from "./images/video.svg";

const EventTile = ({ eventObj }) => (
  <Flex
    borderRadius="8px"
    bg="#F3F4F6"
    width="100%"
    mb="24px"
    flexDirection="column"
    alignItems="flex-start"
    padding="12px 14px 12px"
    maxWidth="450px"
    className="homepage-event-tile"
    css={css`
      transition: all 0.3s ease-in-out 0s;

      .event-right-icon {
        margin-right: 8px;
        transition: all 0.3s ease-in-out 0s;
        align-self: flex-end;
      }

      @media (max-width: 1120px) and (min-width: 760px) {
        .event-desc-div {
          padding-left: 14px;
        }

        .image-div {
          min-width: 130px;
          width: 130px;
          height: 104px;
        }

        .event-desc-div {
          height: 104px;
        }

        .event-card-heading {
          font-size: 17px;
          /* margin-bottom: 0; */
        }

        .event-card-desc {
          font-size: 14.8px;
        }
      }

      @media (max-width: 860px) and (min-width: 760px) {
        .event-card-heading {
          font-size: 16px;
          margin-bottom: 2px;
          /* line-height: 1.3; */
        }

        .event-card-desc {
          font-size: 14.5px;
          /* line-height: 1.35; */
        }
      }

      @media (max-width: 820px) and (min-width: 760px) {
        .event-card-heading {
          line-height: 1.3;
        }

        .event-card-desc {
          /* line-height: 1.3; */
        }
      }

      @media (max-width: 780px) and (min-width: 767px) {
        .event-card-desc {
          line-height: 1.3;
        }
      }

      @media (max-width: 600px) {
        .event-right-icon {
          margin-top: 0px;
        }

        .event-desc-div {
          padding-left: 14px;
        }
      }

      &:hover {
        box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);

        /* .event-right-icon {
          transform: translateX(4px);
        } */
      }
    `}
  >
    <Flex
      css={css`
        @media (max-width: 600px) {
          align-items: center;

          .image-div {
            min-width: 107px;
            width: 107px;
            height: 90px;
          }

          .event-desc-div {
            font-size: 14px;
            height: auto;
            line-height: 1;
          }

          .event-card-heading {
            font-size: 16px;
            margin-bottom: 0;
          }

          .event-card-desc {
            font-size: 14.8px;
          }
        }

        @media (max-width: 428px) {
          .event-card-desc {
            margin-top: 3px;
            font-size: 13px;
          }
        }

        @media (max-width: 380px) {
          .event-card-desc {
            margin-top: 0px;
            font-size: 12px;
          }
        }
      `}
    >
      <Image
        height="120px"
        minWidth="150px"
        borderRadius="6px"
        className="image-div"
        src={eventObj.imgSrc}
        alt={eventObj.heading}
      />
      <Flex
        pl="16px"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        height="120px"
        className="event-desc-div"
      >
        <div>
          <Text
            color="hsla(215,35%,16%,1)"
            fontWeight="600"
            fontSize="20px"
            lineHeight="1.5"
            mb="4px"
            className="event-card-heading"
          >
            {eventObj.heading}
          </Text>
          <Text
            color="hsla(216,14%,29%,1)"
            fontWeight="400"
            fontSize="16px"
            lineHeight="1.5"
            mb="0px"
            className="event-card-desc"
          >
            {eventObj.desc}
          </Text>
        </div>
      </Flex>
    </Flex>
  </Flex>
);

const EventsList = () => {
  const eventListArr = [
    {
      id: 1,
      heading: "MySQL, MariaDB, and Oracle",
      desc: "Ship faster with our new latest database integrations.",
      link: `https://hasura.io/events/webinar/product-launch/`,
      imgSrc: `https://res.cloudinary.com/dh8fp23nd/image/upload/v1683046216/main-web/Frame_2608614_lrd4lu.png`,
    },
    {
      id: 2,
      heading: "Hasura Tutorial",
      desc: "Jump start your Hasura learning and start building with this 30 minutes primer.",
      link: `https://hasura.io/events/webinar/get-started-with-hasura/`,
      imgSrc: `https://res.cloudinary.com/dh8fp23nd/image/upload/v1680798179/education_n9cmkv.jpg`,
    },
    {
      id: 3,
      heading: "What's new in Hasura",
      desc: "Explore new and upcoming features in the latest community call.",
      link: `https://hasura.io/community/community-call/`,
      imgSrc: `https://res.cloudinary.com/dh8fp23nd/image/upload/v1680791502/main-web/comcall_uenmoz.png`,
    },
  ];

  return (
    <Box
      css={css`
        margin: 0 auto;

        a {
          max-width: 450px;
        }

        @media (max-width: 1024px) and (min-width: 700px) {
          /* padding-top: 30px; */
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          a {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        @media (max-width: 767px) {
          padding-top: 40px;

          a {
            max-width: 100%;
            min-width: 100%;
          }

          .homepage-event-tile {
            max-width: 100%;
          }
        }

        @media (max-width: 600px) {
          padding-top: 30px;
        }
      `}
    >
      {eventListArr.map(eventObj => {
        return (
          <a href={eventObj.link} key={eventObj.id} className="home-event-link">
            <EventTile eventObj={eventObj} />
          </a>
        );
      })}
    </Box>
  );
};

const StyledSectionWrapperHomeTopBanner = styled(StyledSectionWrapper)`
  position: relative;
  overflow: hidden;
  .topBannerVideo,
  .topBannerfallback {
    position: absolute;
    top: 90px;
    right: 50px;
    width: 1000px;
  }
  ${mq.between("md", "lg")} {
    .topBannerVideo,
    .topBannerfallback {
      width: 550px;
      right: -77px;
      top: 100px;
    }
  }

  @media (min-width: 992px) and (max-width: 1140px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 700px;
      top: 50px;
      right: -180px;
    }
  }
  @media (min-width: 1141px) and (max-width: 1280px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 800px;
      top: 30px;
      right: -100px;
    }
  }
  @media (min-width: 1281px) and (max-width: 1500px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 800px;
      top: 0;
      right: 20px;
    }
  }
  @media (min-width: 1500px) and (max-width: 1760px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 820px;
      top: 40px;
      right: 30px;
    }
  }
  @media (min-width: 1761px) and (max-width: 1980px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 850px;
      top: 30px;
      right: 8%;
    }
  }
  @media (min-width: 1981px) and (max-width: 2400px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 890px;
      top: 20px;
      right: 9%;
    }
  }
  @media (min-width: 2401px) {
    .topBannerVideo,
    .topBannerfallback {
      width: 875px;
      top: 20px;
      right: 12%;
    }
  }
  ${mq.below.md} {
    .topBannerVideo,
    .topBannerfallback {
      display: none;
    }
  }
`;

const StyledTopBannerWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;

  .zIndexPos {
    position: relative;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 2fr 2fr;
  }

  @media (max-width: 930px) {
    .zIndexPos {
      margin-right: 30px;
    }
  }

  @media (max-width: 850px) {
    grid-gap: 0px;
  }

  @media (max-width: 860px) {
    .home-event-link {
      margin: 0;
    }
  }

  @media (min-width: 1024px) {
    .zIndexPos {
      padding-right: 20px;
    }
  }

  ul {
    li {
      padding: 10px 0;
      align-items: center;
      img {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 1024px) {
    /* grid-template-columns: 1fr; */
  }

  ${mq.between("md", "lg")} {
    .buttonWrapper {
      position: relative;
    }
  }

  ${mq.below.md} {
    grid-template-columns: 1fr;
  }

  .cls-main-cta-fix {
    min-width: 260px;
    width: 260px;
  }
`;

const TopBanner = () => (
  <StyledSectionWrapperHomeTopBanner css={removePaddBottom}>
    {/* <video
      id="vid"
      controls={false}
      autoPlay
      muted
      loop
      className="topBannerVideo"
      poster="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669827015/main-web/topbanner-fallback_1_ftlb1z.jpg"
      width="800"
      height="552"
    >
      <source
        src="https://graphql-engine-cdn.hasura.io/assets/main-site/topbanner-video.mp4"
        type="video/mp4"
      />
    </video> */}
    <StyledContainerWrapper>
      <StyledTopBannerWrapper>
        <div>
          <StyledTitle2B className="zIndexPos" paddingBottom="pb40">
            Instant GraphQL on all your data
          </StyledTitle2B>
          <StyledDesc1 variant="grey_90" fontWeight="font_600" paddingBottom="pb40">
            <ul>
              <li>
                <Image src={tickBlue} alt="Tick" width="30" height="30" pt="3px" />
                Build modern apps & APIs 10x faster
              </li>
              <li>
                <Image src={tickBlue} alt="Tick" width="30" height="30" pt="3px" />
                Built in Authorization & Caching
              </li>
              <li>
                <Image src={tickBlue} alt="Tick" width="30" height="30" pt="3px" />
                Blazing fast GraphQL & REST APIs
              </li>
              <li>
                <Image src={tickBlue} alt="Tick" width="30" height="30" pt="3px" />
                Open source
              </li>
            </ul>
          </StyledDesc1>
          <div className="buttonWrapper">
            <a
              href="https://cloud.hasura.io/signup?pg=home&plcmt=body-banner&cta=get-started-in-30-seconds&tech=default"
              onClick={() => {
                saTrack("click", {
                  placement: "hero banner",
                  cta: "Get Started in 30 seconds",
                  page: "homepage",
                  label: "Clicked Get Started in 30 seconds",
                  action: "click",
                });
              }}
            >
              <StyledButton variant="blue" size="lg" className="cls-main-cta-fix">
                Get Started in 30 seconds
              </StyledButton>
            </a>
          </div>
        </div>
        <EventsList />
      </StyledTopBannerWrapper>
    </StyledContainerWrapper>
  </StyledSectionWrapperHomeTopBanner>
);

export default TopBanner;
