import React from "react";
import styled, { css } from "styled-components";

import { Image, Heading, Flex, Text } from "../../globals/UIKit";
import { Icon } from "../../globals/icons";

export const ReadMoreButton = () => (
  <Flex
    role="button"
    mt="24px"
    bg="#3970FD"
    width="180px"
    border="none"
    borderRadius="100px"
    px="28px"
    height="48px"
    justifyContent="center"
    css={css`
      .arrow {
        max-width: 16px !important;
        min-width: 16px !important;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        background: #1e56e3;

        .arrow {
          transform: translateX(4px);
        }
      }
    `}
  >
    <Text
      fontSize="16px"
      fontWeight="500"
      color="#fff"
      lineHeight="1.5"
      mr="4px"
      css={css`
        white-space: nowrap;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      `}
    >
      Watch recordings
    </Text>
    <Icon
      className="arrow"
      variant="chevron_right"
      color="white"
      size="sm"
      width="16px"
      maxWidth="16px"
    />
  </Flex>
);

const StyledPromoBannerWrapper = styled.div`
  font-family: "IBM Plex Sans" !important;
  background: #ffef93;
  background-image: url("https://res.cloudinary.com/dh8fp23nd/image/upload/v1683717698/Mask_group_6_fymxk3.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: right 100%;
  max-height: 464px;
  padding: 92px 60px;

  box-shadow: 0px 12px 16px -4px rgba(28, 38, 63, 0.08), 0px 4px 6px -2px rgba(28, 38, 63, 0.03);

  h2 {
    font-family: "IBM Plex Sans" !important;
  }

  @media (max-width: 960px) {
    background-size: 25%;
    background-position: right 150px;
  }

  @media (max-width: 860px) {
    background-image: none;
    padding: 45px 60px;
  }

  @media (max-width: 600px) {
    padding: 45px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
`;

const PromoBannerSmall = () => {
  return (
    <StyledPromoBannerWrapper>
      <Image
        src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1683654434/Group_2608660_fqunay.png"
        alt="HasuraCon 2023"
        width="100%"
        maxWidth="213px"
        mb="24px"
      />
      <Heading
        as="h2"
        fontWeight="700"
        color="#1F2A37"
        letterSpacing="-0.02em"
        css={css`
          font-size: 36px !important;
          font-weight: 700 !important;
          width: 75%;
          line-height: 125% !important;

          @media (max-width: 960px) {
            width: 100%;

            font-size: 24px !important;
          }
        `}
      >
        The fourth annual Hasura User Conference
      </Heading>
      <a href="https://hasura.io/events/hasura-con-2023#event-schedule">
        <ReadMoreButton />
      </a>
    </StyledPromoBannerWrapper>
  );
};

export default PromoBannerSmall;
