// import React, { useEffect, useRef, useState } from "react";
import React, { useState } from "react";
import styled from "styled-components";

// import { isBrowser } from "../../utils/constants";
import CommonLinkContainer from "./commonlinkcontainer";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { saTrack } from "../../utils/segmentAnalytics";
import { StyledTitle4, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import {
  textCenter,
  fontBold,
  removePaddTop,
  removePaddBottom,
  showTab,
  hideTab,
  textUppercase,
} from "../shared/CommonStyled";
import { Image } from "../../globals/UIKit";
import { mq } from "../../globals/utils";
import benefit1 from "../imagescommon/query-compilation.svg";
import benefit2 from "../imagescommon/authz-predicate.svg";
import benefit3 from "../imagescommon/json-aggregations.svg";
import benefit4 from "../imagescommon/scale-up.svg";
import concurrency from "./images/concurrency.png";
import globeGradient from "./images/globe-bg.svg";

const StyledSectionWrapperBlazingBg = styled(StyledSectionWrapper)`
  background-image: ${props =>
    props.isVisible ? "url(https://graphql-engine-cdn.hasura.io/assets/main-site/globe.jpg)" : ""};

  background-size: cover;
  position: relative;
  background-position: top;
  .zIndex10 {
    z-index: 10;
    position: relative;
    background-image: url(${globeGradient});
    background-repeat: repeat-x;
    background-position: bottom;
    padding-bottom: 170px;
    padding-top: 200px;
  }
  ${mq.between("md", "lg")} {
    .zIndex10 {
      padding-top: 150px;
      padding-bottom: 100px;
    }
  }
  ${mq.below.md} {
    .zIndex10 {
      padding-bottom: 60px;
      z-index: 0;
      padding-top: 100px;
    }
  }
`;

const StyledBlazingFastWrapper = styled.div`
  .wd60 {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
  }
  .blazingFastGraphWrapper {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 50px;
    .chartWrapper {
      text-align: center;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 52px 0;
      border-radius: 8px;
      img {
        height: 450px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    .wd60 {
      width: 100%;
      padding-bottom: 60px;
    }
    .mobileAlign {
      text-align: left;
    }
  }
  ${mq.below.md} {
    .wd60 {
      width: 100%;
      padding-bottom: 60px;
    }
    .mobileAlign {
      text-align: left;
    }
    .blazingFastGraphWrapper {
      .chartWrapper {
        img {
          height: auto;
        }
      }
    }
    .textCenter {
      text-align: left;
    }
  }
`;
const StyledBlazingFastDescWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px 120px;
  padding-bottom: 80px;
  .descList {
    display: flex;
    align-items: flex-start;
    img {
      margin-top: 3px;
      margin-right: 20px;
      width: 48px;
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding-bottom: 20px;
    .descList {
      img {
        width: 32px;
      }
    }
  }
`;

const BlazingFast = () => {
  const [currentActive] = useState("querysize");

  // const [isVisible, setIsVisible] = useState(false);

  // const globeImageRef = useRef();

  // useEffect(() => {
  //   if (isBrowser) {
  //     if (!globeImageRef?.current) {
  //       return;
  //     }

  //     const observer = new IntersectionObserver(([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsVisible(true);
  //         observer.unobserve(entry.target);
  //       }
  //     });

  //     observer.observe(globeImageRef.current);
  //   }
  // }, [globeImageRef]);

  return (
    <StyledSectionWrapperBlazingBg
      css={removePaddTop + " " + removePaddBottom}
      // ref={globeImageRef}
      isVisible={true}
    >
      <div className="zIndex10">
        <StyledContainerWrapper>
          <StyledBlazingFastWrapper>
            <StyledDesc2
              variant="grey_0"
              paddingBottom="pb8"
              css={textUppercase + " " + textCenter}
              className="mobileAlign"
            >
              5x-50x more performant
            </StyledDesc2>
            <StyledTitle4 className="wd60 mobileAlign" variant="grey_0">
              Blazing fast API performance, that’s remarkably easy to scale
            </StyledTitle4>
            <StyledBlazingFastDescWrapper>
              <StyledDesc1 className="descList" variant="grey_10">
                <Image src={benefit1} alt="Icon" />
                <span>
                  <span css={fontBold}>Query compilation:</span>
                  <br />
                  GraphQL queries are processed in a JIT compiler to create efficient queries that
                  prevent N+1 problems
                </span>
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_10">
                <Image src={benefit2} alt="Icon" />
                <span>
                  <span css={fontBold}>Authz predicate push-down:</span>
                  <br />
                  Authorization policies are compiled into data access queries, avoiding multiple
                  trips, and resulting in a massive performance boost in production
                </span>
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_10">
                <Image src={benefit3} alt="Icon" />
                <span>
                  <span css={fontBold}>JSON aggregations:</span>
                  <br />
                  Hasura avoids the cartesian product problem while fetching data from underlying
                  systems by performing JSON aggregations in the upstream database itself
                </span>
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_10">
                <Image src={benefit4} alt="Icon" />
                <span>
                  <span css={fontBold}>Scale-up and scale-out:</span>
                  <br />
                  Get linear scalability, both vertically and horizontally, without any
                  configuration
                </span>
              </StyledDesc1>
            </StyledBlazingFastDescWrapper>
            <div className="blazingFastGraphWrapper">
              <div className="chartWrapper" css={currentActive === "querysize" ? showTab : hideTab}>
                <Image
                  loading="lazy"
                  src="https://graphql-engine-cdn.hasura.io/assets/main-site/query-size.png"
                  alt="Query size"
                  width="627"
                  height="450"
                />
              </div>
              <div
                className="chartWrapper"
                css={currentActive === "concurrency" ? showTab : hideTab}
              >
                <Image loading="lazy" src={concurrency} alt="Concurrency" />
              </div>
            </div>
            <div className="textCenter">
              <CommonLinkContainer
                darkMode={true}
                bgClass="hasura-blue-bg-color commonlinkContainerWrapperDark"
                decs="Join our webinar on GraphQL Observability to optimise your query performance"
                linkUrl="/events/webinar/diagnosing-improving-query-performance/"
                internalLink={true}
                videoIcon={true}
                onClick={() => {
                  saTrack("click", {
                    placement: "PERFORMANT",
                    cta: "Join our webinar on GraphQL Observability to optimise your query performance",
                    page: "homepage",
                    label:
                      "Clicked Join our webinar on GraphQL Observability to optimise your query performance",
                    action: "click",
                  });
                }}
              />
            </div>
          </StyledBlazingFastWrapper>
        </StyledContainerWrapper>
      </div>
    </StyledSectionWrapperBlazingBg>
  );
};

export default BlazingFast;
