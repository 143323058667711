import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledButton from "../shared/StyledButton";

import { saTrack } from "../../utils/segmentAnalytics";
import { StyledSubTitle2, StyledDescCode14, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { alignSelfStart } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const scrollToTop = () => {
  if (typeof window !== undefined) {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
};

const StyledCloudOfferList = styled.div`
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATION.l_3};
  border-radius: 8px;
  padding: 32px;
  display: grid;
  transition: all .3s ease-in-out;
  &:hover {
    box-shadow: ${ELEVATION.l_1};
  }
  .cloudOfferImg {
    padding-bottom: 24px;
  }
  .posRel {
    position: relative;
    .pos {
      position: absolute;
      left: 0;
      top: -20px;
    }
  }
  .buttonWrapper {
    align-self: flex-end;
  }
  ${mq.between("md", "lg")} {
    padding: 15px;
  }
  ${mq.below.md} {
  }
`;

const IndivProduct = ({ product }) => {
  return (
    <StyledCloudOfferList>
      <div css={alignSelfStart}>
        <div className="cloudOfferImg">
          <img src={product.imgSrc} alt={product.title} />
        </div>
        <StyledSubTitle2 className="posRel" paddingBottom="pb16">
          {product.recommend ? <StyledDescCode14 className="pos" variant="sky_80">{product.recommend}</StyledDescCode14> : null}
          {product.title}
        </StyledSubTitle2>
        <StyledDesc2 paddingBottom="pb24">{product.desc}</StyledDesc2>
      </div>
      <div className="buttonWrapper">
        {product.internalLink ? (
          <Link to={product.btnLink}
            onClick={() => {
              scrollToTop();
              saTrack("click", {
                placement: "Cloud offering",
                cta: product.btnText,
                page: "homepage",
                label: "Clicked " + product.btnText,
                action: 'click',
              });
            }}
          >
            <StyledButton variant={product.btnVariant} btnWidth="width100">{product.btnText}</StyledButton>
          </Link>
        ) : (
          <a href={product.btnLink} target="_blank" rel="noopener noreferrer"
            onClick={() => {
              saTrack("click", {
                placement: "Cloud offering",
                cta: product.btnText,
                page: "homepage",
                label: "Clicked " + product.btnText,
                action: 'click',
              });
            }}
          >
            <StyledButton variant={product.btnVariant} btnWidth="width100">{product.btnText}</StyledButton>
          </a>
        )}
      </div>
    </StyledCloudOfferList>
  );
};

export default IndivProduct;
