import styled from "styled-components";

import { COLORS, TYPOGRAPHY } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const StyledTabWrapper = styled.div`
  display: flex;
  .hasura-common-tab {
    background-color: transparent;
    border: 0;
    color: ${COLORS.sky_0};
    ${TYPOGRAPHY.btn_small}
    border-radius: 3.2px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: 10px 24px;
    transition: all .3s ease-in-out;
    &:hover {
      background-color: #1B2738;
    }
    &:focus {
      outline: none;
      background-color: ${COLORS.sky_70};
    }
  }
  .activeTab {
    background-color: ${COLORS.sky_70};
    box-shadow: 0px 1.6px 2px rgba(0, 0, 0, 0.2);
  }
  ${mq.below.md} {
    .hasura-common-tab {
      padding: 10px;
    }
  }
`;

export default StyledTabWrapper;
