import React, { Fragment } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import CommonLinkContainer from "./commonlinkcontainer";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { Image } from "../../globals/UIKit";
import { saTrack } from "../../utils/segmentAnalytics";
import { StyledTitle4, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import arrowCircleBlue from "./images/arrow-circle-blue.svg";
import arrowCircleGreen from "./images/arrow-circle-green.svg";
import arrowBlue from "./images/arrow-blue.svg";
import pipe from "./images/pipe-white.png";
import daikinNetlap from "./images/daikin-netlap-white.svg";
import arrowWhite from "./images/arrow-right-white.svg";

const caseStudyState = [
  {
    title: "Pipe",
    imgSrc: pipe,
    desc: "Rapidly growing fintech startup Pipe went from prototype to production with Hasura in 11 Days and estimates $600k USD of cost savings annually as they needed 50% fewer developers with Hasura.",
    author: "peter downs",
    designation: "director of engineering, Pipe",
    linkUrl: "/case-studies/pipe",
    bgClass: "philipsCaseStudyBg",
    arrowImg: arrowCircleBlue,
    externalLink: false,
    logoWidth: "86px",
  },
  {
    title: "Daikin Netlap",
    imgSrc: daikinNetlap,
    desc: "Daikin Vietnam worked with Nexlab on a project to modernize their legacy technology stack. Nexlab chose Hasura for this initiative, and the end result was a project delivered ahead of schedule and at a 50% lower cost than planned.",
    author: "Thi Huynh",
    designation: "Founder & CEO at Nexlab Technology",
    linkUrl:
      "https://hasura.io/blog/case-study-how-nexlab-used-hasura-to-digitize-daikin-vietnams-ac-servicing-business-unit/",
    bgClass: "optumCaseStudyBg",
    arrowImg: arrowCircleGreen,
    externalLink: true,
    logoWidth: "235px",
  },
];

const scrollToTop = () => {
  if (typeof window !== undefined) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

const StyledUseCaseStudyWrapper = styled.div`
  padding-bottom: 140px;
  .useCaseStudyHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding-bottom: 100px;
  }
  .useCaseStudyListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    .useCaseStudyList {
      box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.36);
      border-radius: 8px;
      padding: 38px;
      display: grid;
      position: relative;
      transition: all 0.3s ease-in-out;
      .arrowPos {
        position: absolute;
        right: 40px;
        bottom: 40px;
      }
      .useCaseStudyImg {
        color: #fff;
        padding-bottom: 40px;
        img {
          max-height: 24px;
        }
      }
      .arrow {
        margin-left: 0px;
      }
      &:hover {
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.38);
        .arrow {
          transform: translateX(5px);
        }
      }
      &:focus {
        outline: none;
        text-decoration: none;
      }
    }
    .philipsCaseStudyBg {
      background: linear-gradient(346.62deg, #0b5599 2.94%, #8cc3f5 158.75%);
    }
    .optumCaseStudyBg {
      background: linear-gradient(348.14deg, #006378 12.34%, #b4edf9 153.06%);
    }
  }

  ${mq.between("md", "lg")} {
    z-index: 1000;
    position: relative;
    padding-bottom: 60px;
    .useCaseStudyHeader {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      padding-bottom: 60px;
    }
    .useCaseStudyListWrapper {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
  }
  ${mq.below.md} {
    z-index: 0;
    position: relative;
    padding-bottom: 40px;
    .useCaseStudyHeader {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      padding-bottom: 60px;
      .articleTitle {
        padding-right: 0;
        width: 90%;
      }
    }
    .useCaseStudyListWrapper {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      .useCaseStudyList {
        padding: 24px;
      }
    }
  }
`;

const UseCaseStudy = () => {
  const caseStudy = caseStudyState.map((list, index) => {
    return (
      <Fragment key={index}>
        {list.externalLink ? (
          <a
            href={list.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={"useCaseStudyList " + [list.bgClass]}
          >
            <div className="alignSelfStart">
              <div className="useCaseStudyImg">
                <Image src={list.imgSrc} alt={list.title} width={list.logoWidth} height="24" />
              </div>
              <StyledDesc1 variant="white" paddingBottom="pb32">
                {list.desc}
              </StyledDesc1>
            </div>
            <div className="alignSelfEnd">
              <StyledDesc2 variant="white">
                Read Case Study{" "}
                <Image className="arrow" src={arrowWhite} alt="Arrow" width="16" height="16" />
              </StyledDesc2>
            </div>
          </a>
        ) : (
          <Link
            onClick={scrollToTop}
            to={list.linkUrl}
            className={"useCaseStudyList " + [list.bgClass]}
          >
            <div className="alignSelfStart">
              <div className="useCaseStudyImg">
                <Image src={list.imgSrc} alt={list.title} width={list.logoWidth} height="24" />
              </div>
              <StyledDesc1 variant="white" paddingBottom="pb32">
                {list.desc}
              </StyledDesc1>
            </div>
            <div className="alignSelfEnd">
              <StyledDesc2 variant="white">
                Read Case Study
                <Image className="arrow" src={arrowWhite} alt="Arrow" width="16" height="16" />
              </StyledDesc2>
            </div>
          </Link>
        )}
      </Fragment>
    );
  });

  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledUseCaseStudyWrapper>
          <div className="useCaseStudyHeader">
            <div>
              <StyledDesc1>
                <a href="/case-studies/" target="_blank" rel="noopener noreferrer">
                  Case Studies
                  <div className="arrow">
                    <Image src={arrowBlue} alt="Arrrow" width="7" height="10" mt="-2px" />
                  </div>
                </a>
              </StyledDesc1>
              <StyledTitle4>
                See how our customers are using Hasura to power their technology
              </StyledTitle4>
            </div>
            <div className="alignSelfEnd">
              <CommonLinkContainer
                bgClass="commonWhiteBgColor"
                decs="Airbus built a multi-tenant geospatial data Platform with Hasura"
                linkUrl="https://www.youtube.com/watch?v=vJKEslpFMIA&t=3s"
                onClick={() => {
                  saTrack("click", {
                    placement: "USER STORIES",
                    cta: "Airbus built a multi-tenant geospatial data Platform with Hasura",
                    page: "homepage",
                    label:
                      "Clicked Airbus built a multi-tenant geospatial data Platform with Hasura",
                    action: "click",
                  });
                }}
              />
            </div>
          </div>
          <div className="useCaseStudyListWrapper">{caseStudy}</div>
        </StyledUseCaseStudyWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default UseCaseStudy;
