import React from "react";
import styled from "styled-components";

import IndivProduct from "./indivproduct";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { StyledTitle4, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { textCenter, textUppercase } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import hasuraCloud from "../imagescommon/hasura-cloud.svg";
import hasuraCe from "../imagescommon/hasura-ce.svg";
import hasuraEe from "../imagescommon/hasura-ee.svg";
import { HASURA_DOCS_LINK } from "../../utils/constants";

const productState = [
  {
    imgSrc: hasuraCe,
    title: "Hasura CE",
    desc: "Open-source. Includes all core features. Deploy anywhere with Docker.",
    btnText: "Go to Docs",
    btnVariant: "transparent_gray",
    btnLink: HASURA_DOCS_LINK,
  },
  {
    imgSrc: hasuraCloud,
    title: "Hasura Cloud",
    recommend: "Recommended",
    desc: "Fully Managed. Easiest way to get started. Scales as you grow.",
    btnText: "Get Started",
    btnVariant: "blue",
    btnLink: "https://cloud.hasura.io/signup?pg=products&plcmt=body&cta=get-started&tech=default",
  },
  {
    imgSrc: hasuraEe,
    title: "Hasura EE",
    desc: "Enterprise ready version of Hasura CE for on-prem deployments. Added security & performance features.",
    btnText: "Talk to Sales",
    btnVariant: "transparent_gray",
    internalLink: true,
    btnLink: "/contact-us/?type=hasuraenterprise",
  },
];

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const StyledCloudOfferWrapper = styled.div`
  .wd70 {
    width: 70%;
    margin: 0 auto;
  }
  .cloudOfferListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 70px;
    padding-top: 80px;
  }
  ${mq.between("md", "lg")} {
    .wd70 {
      width: 100%;
    }
    .mobileAlign {
      text-align: left;
    }
    .cloudOfferListWrapper {
      padding-top: 60px;
      grid-gap: 16px;
    }
  }
  ${mq.below.md} {
    .wd70 {
      width: 100%;
    }
    .mobileAlign {
      text-align: left;
    }
    .cloudOfferListWrapper {
      grid-template-columns: 1fr;
      padding-top: 40px;
      grid-gap: 32px;
    }
  }
`;
const CloudOffer = () => {
  const productWrapper = productState.map((product, index) => {
    return <IndivProduct key={index} product={product} />;
  });
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledCloudOfferWrapper>
          <StyledDesc2
            className="mobileAlign"
            variant="grey_90"
            paddingBottom="pb8"
            css={textCenter + " " + textUppercase}
          >
            START USING HASURA
          </StyledDesc2>
          <StyledTitle4 className="wd70 mobileAlign" css={textCenter}>
            Get started with our Cloud offering or talk to our sales team for a consultation
          </StyledTitle4>
          <div className="cloudOfferListWrapper">{productWrapper}</div>
        </StyledCloudOfferWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default CloudOffer;
