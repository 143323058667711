import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";
import { Image } from "../../globals/UIKit";
import {
  StyledTitle4,
  StyledDescCode14,
  StyledDesc1,
  StyledDesc2,
} from "../shared/StyledTypography";
import { textCenter, fontBold, textUppercase } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { scrollToTop } from "../../utils/helpers";

import benefit1 from "../imagescommon/rapid.svg";
import benefit2 from "../imagescommon/modern.svg";
import benefit3 from "../imagescommon/dataapi.svg";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const StyledDeliveringWrapper = styled.div`
  .wd60 {
    width: 60%;
    margin: 0 auto;
  }
  .buttonWrapper {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  }
  ${mq.between("md", "lg")} {
    .wd60 {
      width: 100%;
    }
    .mobileAlign {
      text-align: left;
    }
    .buttonWrapper {
      justify-content: flex-start;
      padding-bottom: 70px;
    }
  }
  ${mq.below.md} {
    .wd60 {
      width: 100%;
    }
    .mobileAlign {
      text-align: left;
    }
    .buttonWrapper {
      justify-content: flex-start;
      padding-bottom: 50px;
    }
  }
`;
const StyledDeliveringListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 490px;
  grid-gap: 120px;
  .videoMobileView {
    display: none;
  }
  .descList {
    display: flex;
    align-items: flex-start;
    padding-bottom: 50px;
    img {
      margin-top: 3px;
      margin-right: 20px;
      width: 48px;
    }
    .mt-16 {
      margin-top: 16px;
    }
  }
  .deadLineImg {
    border-radius: 8px;
    .ormVideo {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.16);
    }
    img {
      width: 100%;
      border-radius: 8px;
    }
    .pt-40 {
      padding-top: 40px;
      width: 400px;
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    .deadLineImg {
      .pt-40 {
        width: 100%;
      }
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    .descList {
      img {
        width: 32px;
      }
    }
    .hideMobileView {
      display: none;
    }
    .videoMobileView {
      display: block;
    }
    .deadLineImg {
      .pt-40 {
        width: 100%;
      }
    }
  }
`;

const DeadLine = () => {
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledDeliveringWrapper>
          <StyledDesc2
            className="mobileAlign"
            variant="grey_90"
            paddingBottom="pb8"
            css={textUppercase + " " + textCenter}
          >
            KEY USE-CASES
          </StyledDesc2>
          <StyledTitle4 className="mobileAlign" paddingBottom="pb24" css={textCenter}>
            Delivering on a deadline
          </StyledTitle4>
          <StyledDesc1 className="wd60 mobileAlign" css={textCenter}>
            Hasura is used in mission critical applications across Fortune 500 companies and is
            helping enterprise teams deliver on their strategic initiatives in an incremental, rapid
            and risk-free way.
          </StyledDesc1>
          <div className="buttonWrapper">
            <Link onClick={scrollToTop} to="/contact-us/?type=hasuraenterprise">
              <StyledButton variant="blue" size="md">
                Talk to Sales
              </StyledButton>
            </Link>
          </div>
          <StyledDeliveringListWrapper>
            <div>
              <StyledDesc1 className="descList" variant="grey_90">
                <Image src={benefit1} alt="Icon" />
                <span>
                  <span css={fontBold}>Rapid Execution:</span>
                  <br />
                  Build new applications or add new features to an existing application in days
                  instead of weeks, using the power of GraphQL and self-serve access to data.
                  <br />
                  <div className="mt-16">
                    <a href="/case-studies/philips/" target="_blank" rel="noopener noreferrer">
                      Read Philips Healthcare’s case study<div className="arrow">{">"}</div>
                    </a>
                  </div>
                </span>
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_90">
                <Image src={benefit2} alt="Icon" />
                <span>
                  <span css={fontBold}>Application Modernization:</span>
                  <br />
                  Hasura automates 50-80% of your API development work, making your transformation
                  project tractable and within a much shorter timeframe.
                  <br />
                  {/* <div className="mt-16">
                    <a href="/case-studies/optum/" target="_blank" rel="noopener noreferrer">
                      Read UnitedHealth Group's case study<div className="arrow">{">"}</div>
                    </a>
                  </div> */}
                </span>
              </StyledDesc1>
              <StyledDesc1 className="descList" variant="grey_90">
                <Image src={benefit3} alt="Icon" />
                <span>
                  <span css={fontBold}>Data API vs Database Access:</span>
                  <br />
                  Replace direct database access with a scalable, performant and secure data API to
                  create a unified and federated “Core Data Service”
                  <br />
                  <div className="mt-16">
                    <a href="/case-studies/cherre/" target="_blank" rel="noopener noreferrer">
                      Read Cherre’s case study<div className="arrow">{">"}</div>
                    </a>
                  </div>
                </span>
              </StyledDesc1>
            </div>
            <div>
              <div className="deadLineImg">
                <video
                  id="vid"
                  controls={false}
                  autoPlay
                  muted
                  loop
                  className="ormVideo hideMobileView"
                  width="490"
                  height="459"
                  poster="https://res.cloudinary.com/dh8fp23nd/image/upload/v1670413191/main-web/orm_d0nctl.png"
                >
                  <source
                    src="https://res.cloudinary.com/dh8fp23nd/video/upload/v1670411871/main-web/video/orm_new_gweeiq.mp4"
                    type="video/mp4"
                  />
                </video>
                <div className="videoMobileView">
                  <div className="deadLineImg">
                    <Image
                      loading="lazy"
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1670413191/main-web/orm_d0nctl.png"
                      alt="ORM"
                      width="311"
                      height="311"
                    />
                  </div>
                </div>
                <StyledDescCode14 className="pt-40" variant="grey_80">
                  Massive reduction in cost, complexity and time to market by collapsing three
                  infrastructure layers into Hasura.
                </StyledDescCode14>
              </div>
            </div>
          </StyledDeliveringListWrapper>
        </StyledDeliveringWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default DeadLine;
