import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/homepagenew/topbanner";
import DatabaseUnified from "../components/homepagenew/databaseunified";
import PowerfulAuth from "../components/homepagenew/powerfulauth";
import BlazingFast from "../components/homepagenew/blazingfast";
import DeadLine from "../components/homepagenew/deadline";
import UseCaseStudy from "../components/homepagenew/usecasestudy";
import { PromoBanner } from "../components/homepagenew/PromoBanner";
import JoinCommunity from "../components/homepagenew/joincommunity";
import UserBrand from "../components/homepagenew/userbrand";
import CloudOffer from "../components/homepagenew/cloudoffer";

const userBrandState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/atlassian_psyivj.png",
    altText: "Atlassian",
    width: "174px",
    height: "120px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/verizon_fwugjl.png",
    altText: "Verizon",
    width: "128px",
    height: "120px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/philips_enbti9.png",
    altText: "Philips",
    width: "124px",
    height: "120px",
  },
  {
    break: true,
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/general-mills_jbck9z.png",
    altText: "General Mills",
    width: "108px",
    height: "120px",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/cvs_ptr5vf.png",
    altText: "cvs",
    width: "106px",
    height: "120px",
  },
  // {
  //   imgSrc:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/optum_lcucxe.png",
  //   altText: "Optum",
  //   width: "103px",
  //   height: "120px",
  // },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681283657/main-web/external-logos/airbus_a01u3u.png",
    altText: "Airbus",
    width: "104px",
    height: "120px",
  },
];

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
};

const canonicalUrl = "https://hasura.io/";

const IndexPage = props => {
  return (
    <Layout location={props.location}>
      <Seo
        title="Instant GraphQL APIs on your data | Built-in Authz & Caching"
        description="Hasura gives you instant GraphQL & REST APIs on new & existing data sources. Connect Hasura to your data & get APIs in under a minute."
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <TopBanner />
      <UserBrand
        imgClass="removeMaxHt"
        userBrandState={userBrandState}
        title="44 of the Fortune 100 Companies use Hasura"
      />
      <DatabaseUnified />
      <PowerfulAuth />
      <BlazingFast />
      <DeadLine />
      <UseCaseStudy />
      <PromoBanner />
      <JoinCommunity />
      <CloudOffer />
    </Layout>
  );
};

export default IndexPage;
