import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReadMoreButton } from "../events/PromoBannerSmall";
import { Image, Box, Heading } from "../../globals/UIKit";
import { isBrowser } from "../../utils/constants";
import { mq } from "../../globals/utils";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

const StyledPromoBannerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  background-color: #ffef93;

  margin-top: ${props => props?.mt || ""};

  box-shadow: 0px 12px 16px -4px rgba(28, 38, 63, 0.08), 0px 4px 6px -2px rgba(28, 38, 63, 0.03);

  background-image: ${props =>
    props.isVisible
      ? "url(https://res.cloudinary.com/dh8fp23nd/image/upload/v1683719043/main-web/cityscape_1_1_liodfr.png)"
      : ""};

  background-repeat: no-repeat;
  background-size: 37%;
  background-position: 100% 100%;

  border-radius: 24px;

  padding: 125px 80px;
  max-height: 522px;

  @media (max-width: 1100px) {
    padding: 100px 60px;

    h2 {
      font-size: 56px !important;
    }
  }

  @media (max-width: 860px) {
    padding: 60px 30px;

    h2 {
      padding-right: 60px;
      font-size: 40px !important;
    }
  }

  @media (max-width: 780px) {
    padding: 60px 30px;

    h2 {
      font-size: 40px !important;
    }

    .smiley-illus {
      display: none;
    }
  }

  .proBrand {
    padding-bottom: 24px;

    img {
      min-width: 256px;
      width: 256px;
      margin-left: -18px;
    }
  }

  .buttonWrapper {
    padding-top: 32px;
  }

  ${mq.below.md} {
    padding: 60px 30px;

    grid-template-columns: 1fr;
    /* padding: 30px; */
    /* background-position: center; */
    h2 {
      padding-right: 90px;
    }

    .proBrand {
      img {
        width: 116px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 45px;

    background-image: none;

    h2 {
      padding-right: 0px;
    }
  }

  ${props =>
    props?.isBannerIntegration &&
    css`
      padding-left: 7% !important;
      border-radius: 0 !important;

      button {
        text-transform: none !important;
        width: 179px !important;
        height: 40px !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 160% !important;
        color: #000000 !important;
        white-space: nowrap !important;
      }

      @media (min-width: 800px) {
        #integration-banner {
          white-space: nowrap;
        }
      }
    `};
`;

export const PromoBanner = props => {
  const [isVisible, setIsVisible] = useState(false);

  const imageRef = useRef();

  useEffect(() => {
    if (isBrowser) {
      if (!imageRef?.current) {
        return;
      }

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });

      observer.observe(imageRef.current);
    }
  }, [imageRef]);

  return (
    <StyledContainerWrapper>
      <StyledPromoBannerWrapper ref={imageRef} isVisible={isVisible || false} mt={props?.mt}>
        <Box maxWidth="525px">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1683654434/Group_2608660_fqunay.png"
            alt="HasuraCon 2023"
            width="100%"
            maxWidth="213px"
            mb="28px"
          />
          <Heading
            as="h2"
            fontSize="48px"
            lineHeight="1.25"
            fontWeight="800"
            color="#1F2A37"
            letterSpacing="-0.02em"
            mb="28px"
          >
            The fourth annual Hasura User Conference
          </Heading>
          <a href="https://hasura.io/events/hasura-con-2023/">
            <ReadMoreButton />
          </a>
        </Box>
      </StyledPromoBannerWrapper>
    </StyledContainerWrapper>
  );
};

export default PromoBanner;
