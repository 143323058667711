import React, { useState } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import styled from "styled-components";

import { Image } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";
// import { formatNumber } from "../../utils/formatNumber";
import { StyledTitle4, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { removePaddTop, asc, alignSelfStart } from "../shared/CommonStyled";
import { isBrowser } from "../../utils/constants";
import communityCall from "./images/community-call.svg";
import discord from "./images/discord-color.svg";
import email from "./images/email-color.svg";
import github from "./images/github-color.svg";
import pull from "./images/pull.json";
import servingLottie from "./images/serving.json";
import petabytesLottie from "./images/petabytes.json";

const scrollToTop = () => {
  if (isBrowser) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

const defaultPullOptions = {
  loop: true,
  autoplay: true,
  animationData: pull,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultServingOptions = {
  loop: true,
  autoplay: true,
  animationData: servingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultPetabytesOptions = {
  loop: true,
  autoplay: true,
  animationData: petabytesLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const StyledJoinCommunityWrapper = styled.div`
  display: grid;
  grid-template-columns: 370px 1fr;
  grid-gap: 70px;
  padding-top: 140px;
  .communitySocialWrapper {
    padding-bottom: 0;
    .communitySocial {
      padding: 16px 18px;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      &:hover {
        background-color: ${COLORS.grey_4};
      }
    }
  }
  .commonCommunityWrapper {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 30px;
    background: ${COLORS.white};
    border: 1px solid #ebf1f7;
    box-shadow: ${ELEVATION.l_1};
    border-radius: 8px;
    padding: 16px;
    .pl-24 {
      padding-left: 24px;
    }
    .communityCallImg {
      padding-bottom: 12px;
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    padding-top: 80px;
    grid-gap: 40px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding-top: 40px;
    .commonCommunityWrapper {
      grid-template-columns: 1fr;
      .pl-24 {
        padding-left: 0;
      }
      .communityImg {
        display: none;
      }
    }
  }
`;

const StyledMobileOverflow = styled.div`
  .topBannerlistWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
    z-index: 10;
    position: relative;
    .topBannerlist {
      display: flex;
      align-items: center;
      margin-right: 64px;
      .topBannerlistImg {
        padding-right: 16px;
        [role="button"] {
          cursor: default;
        }
        svg {
          border-radius: 8px;
        }
      }
    }
  }
  ${mq.below.md} {
    overflow: auto;
    .topBannerlistWrapper {
      padding-top: 40px;
      width: 650px;
      .topBannerlist {
        margin-right: 0;
      }
    }
  }
`;

const JoinCommunity = props => {
  const [githubCount] = useState(0);

  return (
    <StyledSectionWrapper css={removePaddTop}>
      <StyledContainerWrapper>
        <StyledJoinCommunityWrapper>
          <div>
            <StyledTitle4 paddingBottom="pb24">Join our Community</StyledTitle4>
            <StyledDesc1 paddingBottom="pb32">
              Whether you are joining an event, getting the newsletter, writing code, answering
              questions, or meeting fellow Hasura community members, you are welcome here.
            </StyledDesc1>
            <div className="communitySocialWrapper">
              <StyledDesc1 linkVariant="grey_100">
                <a
                  href="https://discord.com/invite/hasura"
                  className="communitySocial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image className="leftIcon" src={discord} alt="Discord" />
                  Discord
                </a>
              </StyledDesc1>
              <StyledDesc1 linkVariant="grey_100">
                <a href="/#footer-newsletter" className="communitySocial">
                  <Image className="leftIcon" src={email} alt="email" />
                  Newsletter
                </a>
              </StyledDesc1>
              <StyledDesc1 linkVariant="grey_100">
                <a
                  href="https://github.com/hasura/graphql-engine/discussions"
                  className="communitySocial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image className="leftIcon" src={github} alt="github" />
                  Github Discussions
                </a>
              </StyledDesc1>
            </div>
          </div>
          <div className="commonCommunityWrapper" css={alignSelfStart}>
            <div className="pl-24" css={asc}>
              <div className="communityCallImg">
                <Image src={communityCall} alt="Community call" />
              </div>
              <StyledDesc2 fontWeight="font_600" paddingBottom="pb32">
                Last Thursday of every month | 9:00 AM PST
              </StyledDesc2>
              <div className="buttonWrapper">
                <a
                  onClick={scrollToTop}
                  href="/community/community-call/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledButton variant="blue" size="md">
                    Register for the Call
                  </StyledButton>
                </a>
              </div>
            </div>
            <div className="communityImg">
              <Image
                loading="lazy"
                src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669903664/main-web/community-img_1_jlkc7f.jpg"
                alt="Community"
              />
            </div>
          </div>
        </StyledJoinCommunityWrapper>
        <StyledMobileOverflow>
          <div className="topBannerlistWrapper">
            <div className="topBannerlist">
              <div className="topBannerlistImg">
                <Lottie options={defaultPullOptions} width={55} />
              </div>
              <StyledDesc1 fontWeight="font_600">
                250M+
                <br />
                downloads
              </StyledDesc1>
            </div>
            <div className="topBannerlist">
              <div className="topBannerlistImg">
                <Lottie options={defaultServingOptions} width={55} />
              </div>
              <StyledDesc1 fontWeight="font_600">
                Serving 10B+
                <br />
                API calls
              </StyledDesc1>
            </div>
            <div className="topBannerlist">
              <div className="topBannerlistImg">
                <Lottie options={defaultPetabytesOptions} width={55} />
              </div>
              <StyledDesc1 fontWeight="font_600" linkVariant="grey_100">
                {githubCount}
                <br />
                <a
                  href="https://github.com/hasura/graphql-engine"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub stars
                </a>
              </StyledDesc1>
            </div>
          </div>
        </StyledMobileOverflow>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};
JoinCommunity.propTypes = {
  location: PropTypes.object,
};

export default function HeaderWithStars(props) {
  return <JoinCommunity data={null} {...props} />;
}
// export default JoinCommunity;
