import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Icon } from "../../globals/icons";
import { StyledDesc3 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
// import { scrollToTop } from "../../utils/helpers";

const StyledSupportedDatabasesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
  ${mq.between("lg", "xl")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
  }
`;

const StyledSupportedDatabasesList = styled.a`
  background: #f9fafb;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  /* position: relative; */
  transition: all 0.3s ease-in-out;
  &.greyBg {
    background: #f7f5f2;
    .dbImg {
      padding: 0 0;
      padding-top: 22px;
      img {
        max-height: 120px;
      }
    }
  }
  &:hover {
    box-shadow: 0px 12px 16px -4px rgba(28, 38, 63, 0.08), 0px 4px 6px -2px rgba(28, 38, 63, 0.03);
  }
  .tagBtn {
    /* position: absolute;
    right: 10px;
    top: 10px; */
    position: relative;
    background: #f3f4f6;
    border: 1px solid #e5e7eb;
    border-radius: 16px;
    padding: 2px 8px;
    font-size: 12px;
    color: #6c737f;
    cursor: pointer;
    &:hover {
      .overlayWrapper {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, 0px);
      }
    }
    &.active {
      border: 1px solid #e5d4ff;
      background: #f2eaff;
      color: #7539d7;
    }
    &.comingSoon {
      border: 1px solid #cfddff;
      background: #e9efff;
      color: #2c64f4;
    }
    .overlayWrapper {
      transition: all 0.3s ease-in-out;
      width: 240px;
      border-radius: 8px;
      padding: 12px 16px;
      position: absolute;
      line-height: 18px;
      left: 50%;
      transform: translate(-50%, -10px);
      bottom: calc(100% + 15px);
      background: #111927;
      color: #fcfcfd;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      text-align: left;
      &:before,
      &:after {
        -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        border-top: 1px solid #111927;
        content: "";
        position: absolute;
      }
      &:before {
        content: "";
        background-color: #111927;
        width: 20px;
        height: 10px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -9px;
      }
    }
  }
  &:nth-child(4n + 1) {
    .tagBtn {
      &:hover {
        .overlayWrapper {
          transform: translate(0%, 0px);
        }
      }
      .overlayWrapper {
        left: unset;
        transform: translate(0, -10px);
        &:before {
          left: 20px;
        }
      }
    }
  }
  &:nth-child(4n) {
    .tagBtn {
      &:hover {
        .overlayWrapper {
          transform: translate(0%, 0px);
        }
      }
      .overlayWrapper {
        left: unset;
        right: 0;
        transform: translate(0, -10px);
        &:before {
          left: unset;
          right: 10px;
        }
      }
    }
  }
  .dbImg {
    padding: 30px 16px;
    text-align: center;
    img {
      max-height: 80px;
      display: inline-block;
    }
  }
  .dbLinkWrapper {
    background: #ffffff;
    border-radius: 0px 0px 12px 12px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dbLink {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      .rotateArrow {
        transform: rotate(-45deg);
        margin-left: 4px;
      }
    }
  }
  ${mq.between("lg", "xl")} {
    &:nth-child(4n + 1),
    &:nth-child(4n) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: none;
          }
        }
        .overlayWrapper {
          left: unset;
          transform: none;
          &:before {
            left: unset;
          }
        }
      }
    }
    &:nth-child(3n + 4) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: translate(0%, 0px);
          }
        }
        .overlayWrapper {
          left: 0;
          transform: translate(0, -10px);
          &:before {
            left: 20px;
          }
        }
      }
    }
    &:nth-child(3n + 3) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: translate(0%, 0px);
          }
        }
        .overlayWrapper {
          left: unset;
          right: 0;
          transform: translate(0, -10px);
          &:before {
            left: unset;
            right: 10px;
          }
        }
      }
    }
    &:nth-child(3n + 2) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: translate(-50%, 0px);
          }
        }
        .overlayWrapper {
          right: unset;
          left: 50%;
          transform: translate(-50%, -10px);
          &:before {
            right: unset;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .dbLinkWrapper {
      .dbLink {
        margin-right: 16px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    &:nth-child(4n + 1),
    &:nth-child(4n),
    &:nth-child(3n + 4),
    &:nth-child(3n + 3),
    &:nth-child(3n + 2) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: none;
          }
        }
        .overlayWrapper {
          left: unset;
          transform: none;
          &:before {
            left: unset;
          }
        }
      }
    }
    &:nth-child(odd) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: translate(0%, 0px);
          }
        }
        .overlayWrapper {
          left: unset;
          transform: translate(0, -10px);
          &:before {
            left: 20px;
          }
        }
      }
    }
    &:nth-child(even) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: translate(0%, 0px);
          }
        }
        .overlayWrapper {
          left: unset;
          right: 0;
          transform: translate(0, -10px);
          &:before {
            left: unset;
            right: 10px;
          }
        }
      }
    }
  }
  ${mq.below.md} {
    &:nth-child(4n + 1),
    &:nth-child(4n),
    &:nth-child(3n + 4),
    &:nth-child(3n + 3),
    &:nth-child(3n + 2) {
      .tagBtn {
        &:hover {
          .overlayWrapper {
            transform: none;
          }
        }
        .overlayWrapper {
          left: unset;
          transform: translate(0, -10px);
          &:before {
            left: unset;
          }
        }
      }
    }
    .tagBtn {
      &:hover {
        .overlayWrapper {
          transform: translate(0%, 0px);
        }
      }
      .overlayWrapper {
        left: 0 !important;
        right: unset !important;
        transform: translate(0, -10px);
        &:before {
          right: unset !important;
          left: 20px !important;
        }
      }
    }
  }
`;

const IndivDBList = ({ db }) => {
  return (
    <StyledSupportedDatabasesList
      className={"dbList" + (db.requestDb ? " greyBg" : "")}
      href={db.linkUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="dbImg">
        <img src={db.imgSrc} alt={db.altText} loading="lazy" width={db.width} height={((db.requestDb) ? "120px" : "80px")} />
      </div>
      <div className="dbLinkWrapper">
        {!db.requestDb ? (
          <StyledDesc3
            className={
              "tagBtn" +
              (db.status === "Available" ? " active" : "") +
              (db.status === "Coming Soon" ? " comingSoon" : "")
            }
          >
            {db.status}
            <div className="overlayWrapper">{db.toolTip}</div>
          </StyledDesc3>
        ) : null}
        <StyledDesc3 className="dbLink" variant="sky_80" css={flexCenter}>
          {db.linkText}
          <Icon className="rotateArrow" variant="arrow_right" size="sm" color="sky_80" />
        </StyledDesc3>
      </div>
    </StyledSupportedDatabasesList>
  );
};

const SupportedDatabases = ({ supportedDatabasesState }) => {
  return (
    <StyledSupportedDatabasesWrapper>
      {supportedDatabasesState.map((db, index) => (
        <IndivDBList key={index} db={db} />
      ))}
    </StyledSupportedDatabasesWrapper>
  );
};

export default SupportedDatabases;
